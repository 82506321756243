import React from 'react';
import './ColumnLayout.css';

const ColumnLayout = (props) => {
  return (
    <div className="tabletext">
      <div className="left-column">
        {props.left}
      </div>
      <div className="right-column">
        {props.right}
      </div>
    </div>
  );
}

export default ColumnLayout;