import React, { Component } from "react";
import './CTA.css';	


export default class CTA extends Component { 
    render () {
        return (
            
            <div className="section"> 
           
            <a href="https://dash.25ventures.net" className="linkdao">
            <teh1 data-text="Entry the DAO"></teh1>
            </a>
            
            
            </div>
        )
}
}