import React, { Component } from "react";
import './Second.css';
import ColumnLayout from './ColumnLayout';





export default class Second extends Component { 
    render () {
        return (
            <div className="section">

<ColumnLayout
      left={
<div className="gif1">
        <img
    src="https://64.media.tumblr.com/f5ff6808190095823215c57d44ffb79a/a2537ce8f6650d10-eb/s500x750/e59e7790bda0579d7b9ada6712c4ad4cfc70639b.gif"
    alt="gif"
  />
  </div>


        
      }

      right={ 
        
            <div className="promoleft">
            We identify pioneers and invest in cutting-edge projects
             that are driving innovation and positive change.
            </div>
      }
    />

            
             </div>    

        );
}
}