import React, { Component } from "react";
import './First.scss';
import Slogan from "./effect text/slogan2";

export default class First extends Component { 
    render () {
        return (
            <div className="section">
            
            <div className="testy">
                <h3 className="title_xxv">XXV</h3>
                <h3 className="title_Ventures">Ventures</h3>
            </div>
           
            <Slogan />
                
                
            
            <p className="version">alpha 0.0.1</p>
             


            </div>    
             
        
        )

        
}
}