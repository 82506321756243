import React, { Component } from "react";
import './Second.css';
import ColumnLayout from './ColumnLayout';



export default class Third extends Component { 
    render () {
        return (
            <div className="section">

<ColumnLayout
      left={
<div className="promoright">
        We Support the growth of emerging and transformative markets + ideas.
      </div>

        
      }

      right={ 
        <div className="gif2">
        <img
    src="http://24.media.tumblr.com/f4a10851fe41ae1a04e54cecd3a3618b/tumblr_mqbb3a73fp1spo3yuo1_400.gif"
    alt="gif"
  />
  </div>
      }
    />

            
             </div>    

        );
}
}