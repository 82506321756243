import React, { Component } from "react";
import './slogan.scss';	

function Slogan() {
    return (
        <div class="content">
  <div class="content__container">
    <p class="content__container__text">
      Embrace
    </p>
    
    <ul class="content__container__list">
      <li class="content__container__list__item">the Progress</li>
      <li class="content__container__list__item">The Development</li>
      <li class="content__container__list__item">The Investment</li>
      <li class="content__container__list__item">The Community</li>
    </ul>
  </div>
</div>

    )   
  }


export default Slogan;