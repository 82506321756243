import React, { Component } from "react";
import './Second.css';
import ColumnLayout from './ColumnLayout';



export default class Fourth extends Component { 
    render () {
        return (
            <div className="section">
        
        <ColumnLayout

      left={
<div className="gif3">
        <img
    src="https://cutewallpaper.org/cdn-cgi/mirage/dd19f2d06ebc24f541f142b37b4289ffa7de722a7607e39984c5c6dd4ce8defd/1280/24/animated-transparent-gif/gifs-for-grownups-colourverse.gif"
    alt="gif"
  />
  </div>
        
      }

      right={ 
        
            <div className="promoleft">
            We cohesively accelerate reciprocal success 
           
            and build foundations for the brighter future.
          </div>
      }
    />
    
                   
             </div>    

        );
}
}